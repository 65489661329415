(function (module) {
  'use script';
  caseSideViewCtrl.$inject = [
    '$rootScope',
    'UserService',
    'BotService',
    'EstablishmentService'
  ];
  function caseSideViewCtrl(
    $rootScope,
    UserService: UserService,
    BotService,
    EstablishmentService: EstablishmentService
  ) {
    const vm = this;
    vm.$onInit = () => {
      vm.currentTab = vm.sideViewMenuOption
        ? vm.sideViewMenuOption
        : 'userMetadata';

      UserService.getProfile().then(user => {
        vm.canInteract = user.isAdminOrCm;
      });
      EstablishmentService.getEstablishment().then(establishment => {
        vm.hasIntegration = establishment.config['custom_data_integration'];
        vm.shopIntegration = establishment.features.shop_integration;
        const integration = establishment.integrations.integration_configs.find(
          ({name}) => name === 'BOTCENTER'
        );
        vm.botCenterEnabled = integration ? integration.config.active : false;
      });

      vm.currentTabAltBg = false;
      vm.activableBots = [];
      vm.botTicketState = null;

      if (vm.botCenterEnabled === true && vm.caseObject !== null) {
        vm.activableBots = $rootScope.bots;
        BotService.getTicketState(vm.caseObject.resource_id).then(state => {
          vm.botTicketState = state;
        });
      }
    };

    vm.setCurrentTab = function (tab) {
      vm.currentTab = tab;
      vm.adVerticalSeparatorCtrl.setMiddleSize();
    };

    vm.hasTags = function () {
      return vm.caseObject.tags ? vm.caseObject.tags.length > 0 : false
    }

    vm.countTags = function () {
      if (!vm.caseObject.tags) return 0
      const count = vm.caseObject.tags.length.toString();
      if (count > 9){
        return '+9';
      }
      return count
    };

    vm.getUserFromCase = function () {
      return vm.caseObject.users.length > 0
        ? vm.caseObject.users[0]
        : vm.caseObject.establishment_users[0];
    };
  }

  module.controller('caseSideViewCtrl', caseSideViewCtrl);
})(angular.module('postCenterWebClientApp'));
